import React from "react";
import header from "../assets/contact_us/contact-header-img.png";
import mail from "../assets/contact_us/fluent_mail-20-filled.svg";
import LazyImage from "../components/common/LazyImage";
import location from "../assets/contact_us/carbon_location-filled.svg";

const HowToReach = () => {
  return (
    <div className="home-wrapper flex justify-center">
      <div className="w-full md:max-w-[1280px] my-6 md:my-[120px] card overflow-hidden">
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-[40%] lg:mb-0 md:mb-5 bgc-image">
            <div className="lg:h-full md:h-96 h-60 flex items-center justify-center">
              <LazyImage
                className="lazy-img md:h-60 h-40"
                src={header}
                alt=""
              />
            </div>
          </div>

          <div className="flex flex-col md:p-10 px-5 py-10 relative w-full lg:w-[60%] bgc-transparent gap-5">
            <h1 className="heading1 lg:block hidden">How to reach us</h1>
            <div className="card px-6 py-4 flex flex-col gap-3">
              <div className="flex flex-col gap-2">
                <p className="heading2">Level 1</p>
                <p>
                  Share your concern, our team will work diligently to resolve
                  the issue promptly.
                </p>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <span
                  className="p-2 rounded-full"
                  style={{
                    background:
                      "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%",
                  }}
                >
                  <img src={mail} alt="" className="h-6 w-6" />
                </span>
                <a href="mailto: care@aiqahealth.com" className="para">
                  care@aiqahealth.com
                </a>
              </div>
            </div>
            <div className="card px-6 py-4 flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <p className="heading2">Level 2</p>
                <p className="para text-[#1B1D21]">
                  If your grievance has not been resolved to your satisfaction,
                  please write to
                </p>
              </div>
              <div className="flex items-center gap-3 mb-3">
                <span
                  className="p-2 rounded-full"
                  style={{
                    background:
                      "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%",
                  }}
                >
                  <img
                    src={location}
                    alt=""
                    className="md:h-6 md:w-6 h-16 w-16"
                  />
                </span>
                <div className="para">
                  <p className="mb-1">Nodal officer customer grievance</p>
                  <p className="font-[500]">
                    doctor on call private limited, 5th floor, sas tower, sector
                    – 38, gurugram, 122001
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToReach;
