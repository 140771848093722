import React from "react";

const HomeCards = () => {
  const data = [
    { number: "80 Lakh+", title: "Lives impacted" },
    { number: "100+", title: "Partners onboarded" },
    { number: "5000+", title: "Pincodes served" },
    { number: "250+", title: "Health centers" },
  ];

  return (
    <>
      <h1 className="heading1 text-center mb-6 md:mb-10">Our impact</h1>
      <div className="flex">
        <div className="text-center flex flex-col">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-5 md:gap-4">
            {data?.map((item, index) => {
              let orderClass = "";
              if (index === 1) {
                orderClass = "order-3";
              } else if (index === 3) {
                orderClass = "order-2";
              } else if (index === 2) {
                orderClass = "order-1";
              }
              return (
                <div
                  className={`flex flex-col justify-center items-center card gap-2 py-6 px-4 lg:min-w-[300px] lg:h-40 md:h-36 h-24 ${orderClass} md:order-none`}
                  key={index}
                >
                  <p className="heading2 md:text-[28px]">{item.number}</p>
                  <p className="para md:min-h-[40px] lg:min-h-max">
                    {item.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCards;
