import React from "react";
import founderImg from "../../assets/about/founderImage.png";
import commaLeftImg from "../../assets/about/comma1.svg";
import commaRightImg from "../../assets/about/comma2.svg";

const FoundersMessage = () => {
  return (
    <div className="founder-message-container flex flex-col lg:flex-row justify-center items-center text-center md:text-left lg:gap-20 md:gap-10 gap-6 p-5 md:p-10">
      <div className="lg:w-[30%]">
        <img alt="" src={founderImg} className="h-[340px] md:h-[400px]" />
        <div
          className="p-4 text-center -mt-14"
          style={{
            backdropFilter: "blur(8px)",
            background:
              "linear-gradient(97.57deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.7) 100%)",
          }}
        >
          <p className="lg:text-[32px] lg:font-[700] lg:leading-[38.4px] text-[24px] font-[700] leading-[28.8px] pb-1">
            Karana Chopra
          </p>
          <p className="lg:text-[20px] lg:leading-[24px] lg:font-[300] text-[16px]">
            Director
          </p>
        </div>
      </div>
      <div className="lg:w-[70%]">
        <div className="relative">
          <span className="absolute md:bottom-4 bottom-3 md:left-auto left-0">
            <img
              src={commaLeftImg}
              alt=""
              className="lg:h-auto lg:w-auto md:h-[60px] md:w-[60px] h-[40px] w-[40px]"
            />
          </span>
          <span className="heading1 leading-[50px] md:leading-[80px] lg:leading-[75px] mb-2 lg:pl-[15%] md:pl-[12%] pl-0">
            Founder's message
          </span>
        </div>

        <div className="relative lg:mb-0 md:mb-[65px] mb-5">
          <span className="para leading-6">
            Our goal is simple yet bold: make comprehensive health & insurance
            benefits accessible and affordable for every Indian, no matter where
            they live or what they earn. We saw the gaps in the system, people
            struggling to get basic care, people pushed into bankruptcy with 1
            hospitalization, and we knew we had to act.
            <br />
            Our platform was born out of a passion to change this reality. With
            the support of over 50 partners and the trust of 80 lakh+ customers,
            we’re leading a movement to make quality health and financial
            protection a right for all.
          </span>
          <span className="inline-block absolute pl-[1%] md:-bottom-[60px] -bottom-5">
            <img
              src={commaRightImg}
              alt=""
              className="lg:h-auto lg:w-auto md:h-[60px] md:w-[60px] h-[40px] w-[40px]"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FoundersMessage;
