import React, { useEffect, useState } from "react";
import LazyImage from "../common/LazyImage";
import teamImg from "../../assets/about/team.png";
import teamTabImg from "../../assets/about/teamTab.png";
import teamMobileImg from "../../assets/about/teamMobile.png";
import HowToApplyForm from "./HowToApplyForm";

const HowToApply = () => {
  const [imageSrc, setImageSrc] = useState(teamImg);

  const handleResize = () => {
    const width = window.innerWidth;

    if (width >= 1024) {
      // lg and above
      setImageSrc(teamImg);
    } else if (width >= 768 && width < 1024) {
      // md breakpoint (tablets)
      setImageSrc(teamTabImg);
    } else {
      // sm breakpoint (mobile)
      setImageSrc(teamMobileImg);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-wrap justify-center mb-[48px] md:mb-20 ">
      <div className="text-center mb-10">
        <p className="heading1 mb-2">How to apply</p>
        <p className="para font-[500] leading-[24px] md:leading-[25.6px] px-5 lg:px-0">
          Explore our current openings. If you see a fit, submit your
          application. Don't see the right role for you? We're always looking
          for talented individuals to join our team. Send us your resume by
          filling out the below form.
        </p>
      </div>
      <div className="w-full lg:w-[100%] px-0 md:px-10 lg:px-0">
        <LazyImage className="lazy-img" src={imageSrc} alt="" />
      </div>
      <div className="lg:w-[90%] md:w-[80%] w-[90%]">
        <HowToApplyForm />
      </div>
    </div>
  );
};

export default HowToApply;
