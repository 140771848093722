import Lottie from "lottie-react-web";
import Animation from "../../assets/lottyAnimation/becomeReseller.json";
import AnimationMobile from "../../assets/lottyAnimation/becomeResellerMobile.json";

const BecomeAReseller = () => {
  return (
    <div className="flex w-full md:max-w-[1248px] mx-auto">
      <div className="hidden md:block lg:py-10 lg:px-0 lottie-animation-cursor md:px-5 md:py-10">
        <Lottie
          options={{
            animationData: Animation,
            loop: true,
            rendererSettings: {},
          }}
        />
      </div>
      <div className="md:hidden lottie-animation-cursor">
        <Lottie
          options={{
            animationData: AnimationMobile,
            loop: true,
            rendererSettings: {},
          }}
        />
      </div>
    </div>
  );
};

export default BecomeAReseller;
